import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextField.figmaUrl.android} codeUrl={checklists.componentTextField.codeUrl.android} checklists={checklists.componentTextField.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`Textfields allow user input. The border should light up simply and clearly indicating which field the user is currently editing.`}</p>
    <p>{`We have basic types of textfield:`}</p>
    <ol>
      <li parentName="ol">{`Basic Text Field`}</li>
      <li parentName="ol">{`Basic Text Field Outline`}</li>
      <li parentName="ol">{`Text Field With Label inline`}</li>
      <li parentName="ol">{`Text Field With Label Outline`}</li>
    </ol>
    <p>{`and then states of textfield:`}</p>
    <ol>
      <li parentName="ol">{`Normal State Inline`}</li>
      <li parentName="ol">{`Normal State Outline`}</li>
      <li parentName="ol">{`Error State Inline`}</li>
      <li parentName="ol">{`Error State Outline`}</li>
      <li parentName="ol">{`Success State Inline`}</li>
      <li parentName="ol">{`Succes State Outline`}</li>
      <li parentName="ol">{`Disable State Inline`}</li>
      <li parentName="ol">{`Disable State Outline`}</li>
    </ol>
    <p>{`You can also implement prefix and suffix for your project.`}</p>
    <p>{`For textfield customization:`}</p>
    <ol>
      <li parentName="ol">{`Prefix Text Inline`}</li>
      <li parentName="ol">{`Prefix Text Outline`}</li>
      <li parentName="ol">{`Suffix Text Inline`}</li>
      <li parentName="ol">{`Suffix Text Outline`}</li>
      <li parentName="ol">{`Left Icon Inline Text Field`}</li>
      <li parentName="ol">{`Left Icon Outline Text Field`}</li>
      <li parentName="ol">{`Right Icon Inline Text Field`}</li>
      <li parentName="ol">{`Right Icon Outline Text Field`}</li>
    </ol>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/android-compose-textfield.gif",
            "alt": "Legion Textfield Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <h3>{`Basic`}</h3>
    <h4>{`Textfield Inline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username"
)
`}</code></pre>
    <h4>{`Textfield Outline`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username"
)
`}</code></pre>
    <h3>{`With Label`}</h3>
    <h4>{`Textfield with Inline Label`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username") {
    LgnInlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
    )
}
`}</code></pre>
    <h4>{`Textfield with Outline Label`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username") {
    LgnOutlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
    )
}
`}</code></pre>
    <h3>{`State`}</h3>
    <h4>{`Normal State Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username") {
    LgnInlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
    )
}

`}</code></pre>
    <h4>{`Normal State Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username") {
    LgnOutlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
    )
}

`}</code></pre>
    <h4>{`Error State Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username", error = "Username Error") {
    LgnInlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
        error = true
    )
}

`}</code></pre>
    <h4>{`Error State Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username", error = "Username Error") {
    LgnOutlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
        error = true,
    )
}

`}</code></pre>
    <h4>{`Success State Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username", success = "Username Correct") {
    LgnInlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
        success = true
    )
}

`}</code></pre>
    <h4>{`Success State Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnFieldContainer(label = "Username", success = "Username Correct") {
    LgnOutlineTextField(
        value = value,
        onValueChange = {
            value = it
        },
        placeholder = "Username",
        singleLine = false,
        success = true,
    )
}

`}</code></pre>
    <h4>{`Disabled State Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    singleLine = false,
    enabled = false
)

`}</code></pre>
    <h4>{`Disabled State Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    singleLine = false,
    enabled = false
)

`}</code></pre>
    <h3>{`Prefix Text`}</h3>
    <h4>{`Prefix Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    prefix = {
        Text("Rp")
    }
)

`}</code></pre>
    <h4>{`Prefix Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    prefix = {
        Text("Rp")
    }
)
`}</code></pre>
    <h3>{`Suffix Text`}</h3>
    <h4>{`Suffix Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    suffix = {
        Text("@telkom.co.id")
    }
)

`}</code></pre>
    <h4>{`Suffix Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    suffix = {
        Text("@telkom.co.id")
    }
)
`}</code></pre>
    <h3>{`Left Icon`}</h3>
    <h4>{`Left Icon Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    iconLeft = {
        Icon(imageVector = Icons.Default.DateRange, contentDescription = "")
    }
)

`}</code></pre>
    <h4>{`Left Icon Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    iconLeft = {
        Icon(imageVector = Icons.Default.DateRange, contentDescription = "")
    }
)
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <h4>{`Right Icon Inline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnInlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    iconRight = {
        Icon(imageVector = Icons.Default.DateRange, contentDescription = "")
    }
)

`}</code></pre>
    <h4>{`Right Icon Outline Textfield`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember { mutableStateOf("") }

LgnOutlineTextField(
    value = value,
    onValueChange = {
        value = it
    },
    placeholder = "Username",
    iconRight = {
        Icon(imageVector = Icons.Default.DateRange, contentDescription = "")
    }
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Text in Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onValueChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(String) -> Unit`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When Text Change`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnTextFieldColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Color of Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textStyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TextStyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Text Style`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Enable or Disable of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether Text Field State Error or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether Text Field State Success or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Icon Left of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconRight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Icon Right of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`prefix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Prefix of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`suffix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`@Composable (() -> Unit)?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Suffix of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Shape of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`placeHolder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Place Holder of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KeyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Options Available on the Keyboard when They Appear on the Screen`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`keyboardActions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KeyboardOptions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`What the Application Should Do after the User has Finished Entering Text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`singleLine`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set The Text Field Whether Single Line or Not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxLines`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Maximum Line of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minLines`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Minimum Line of Text Field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`visualTransformation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VisualTransformation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Changing Visual Output of the Text Field`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      